import React from "react";
import {
  Toolbar,
  SaveButton,
  DeleteButton,
  Button,
  useNotify,
  useRefresh,
} from "react-admin";
import { useNavigate } from "react-router-dom";

const ToolbarFormCancel = (props) => {
  const navigate = useNavigate();
  const notify = useNotify();
  const refresh = useRefresh();

  const onSuccess = (e) => {
    notify("Cambios Guardados");
    navigate(-1);
    refresh();
  };

  const mutationOptions = {};
  if (props.backOnSave) mutationOptions.onSuccess = onSuccess;

  return (
    <Toolbar>
      <SaveButton mutationOptions={mutationOptions} type="button" />
      <Button
        onClick={() => navigate(-1)}
        label="CANCEL"
        variant="outlined"
        sx={{ marginLeft: "2em" }}
        size="medium"
        color="warning"
      />
      <DeleteButton
        mutationOptions={mutationOptions}
        type="button"
        variant="outlined"
        size="medium"
        sx={{ marginLeft: "2em" }}
      />
    </Toolbar>
  );
};

export default ToolbarFormCancel;
