import { Grid } from "@mui/material";
import React from "react";
import {
  BulkExportButton,
  Create,
  CreateButton,
  Datagrid,
  DateField,
  DateInput,
  Edit,
  InfiniteList,
  ListButton,
  NumberField,
  NumberInput,
  SimpleForm,
  TextField,
  TopToolbar,
  required,
} from "react-admin";

const HonorariosValorHoraBulkActionButtons = () => (
  <>
    <CreateButton resource="honorariosValorHora" />
    <BulkExportButton />
  </>
);

const HonorariosValorHoraList = (props) => {
  return (
    <InfiniteList {...props} sort={{ field: "desde", order: "DESC" }}>
      <Datagrid
        bulkActionButtons={<HonorariosValorHoraBulkActionButtons />}
        rowClick="edit"
      >
        <TextField source="id" />
        <DateField source="desde" locales={"es-AR"} />
        <NumberField source="importe_cuidado_diurno" />
        <NumberField source="importe_cuidado_nocturno" />
        <NumberField source="importe_talleres" />
        <NumberField source="importe_sesiones" />
        <NumberField source="importe_limpieza" />
        <NumberField source="adicionales" />
      </Datagrid>
    </InfiniteList>
  );
};

const HonorariosValorHoraEdit = (props) => {
  return (
    <Edit
      {...props}
      redirect={"list"}
      actions={
        <TopToolbar title="Honorarios Valor Hora">
          <ListButton label="Lista" />
        </TopToolbar>
      }
      mutationMode="pessimistic"
    >
      <SimpleForm>
        <Grid
          container
          width="100%"
          columns={4}
          rowSpacing={1}
          columnSpacing={1}
          sx={{ maxWidth: "550px" }}
        >
          <Grid item xs={4} sx={{ maxWidth: "150px", maxHeight: "60px" }}>
            <NumberField source="id" />
          </Grid>
          <Grid item xs={2} sx={{ maxWidth: "150px", maxHeight: "60px" }}>
            <DateInput
              source="desde"
              fullWidth
              validate={required()}
              locales={"es-AR"}
            />
          </Grid>
          <Grid item xs={2} sx={{ maxWidth: "150px", maxHeight: "60px" }}>
            <NumberInput source="importe_cuidado_diurno" defaultValue={0} />
          </Grid>
          <Grid item xs={2} sx={{ maxWidth: "150px", maxHeight: "60px" }}>
            <NumberInput source="importe_cuidado_nocturno" defaultValue={0} />
          </Grid>
          <Grid item xs={2} sx={{ maxWidth: "150px", maxHeight: "60px" }}>
            <NumberInput source="importe_talleres" defaultValue={0} />
          </Grid>
          <Grid item xs={2} sx={{ maxWidth: "150px", maxHeight: "60px" }}>
            <NumberInput source="importe_sesiones" defaultValue={0} />
          </Grid>
          <Grid item xs={2} sx={{ maxWidth: "150px", maxHeight: "60px" }}>
            <NumberInput source="importe_limpieza" defaultValue={0} />
          </Grid>
          <Grid item xs={4} sx={{ maxWidth: "150px", maxHeight: "60px" }}>
            <NumberInput source="adicionales" defaultValue={0} />
          </Grid>
        </Grid>
      </SimpleForm>
    </Edit>
  );
};

const HonorariosValorHoraCreate = (props) => {
  return (
    <Create
      {...props}
      redirect={"list"}
      actions={
        <TopToolbar title="Honorarios Valor Hora">
          <ListButton label="Lista" />
        </TopToolbar>
      }
    >
      <SimpleForm>
        <Grid
          container
          width="100%"
          columns={4}
          rowSpacing={1}
          columnSpacing={1}
          sx={{ maxWidth: "550px" }}
        >
          <Grid item xs={4} sx={{ maxWidth: "150px", maxHeight: "60px" }}>
            <NumberField source="id" />
          </Grid>
          <Grid item xs={2} sx={{ maxWidth: "150px", maxHeight: "60px" }}>
            <DateInput
              source="desde"
              fullWidth
              validate={required()}
              locales={"es-AR"}
            />
          </Grid>
          <Grid item xs={2} sx={{ maxWidth: "150px", maxHeight: "60px" }}>
            <NumberInput source="importe_cuidado_diurno" defaultValue={0} />
          </Grid>
          <Grid item xs={2} sx={{ maxWidth: "150px", maxHeight: "60px" }}>
            <NumberInput source="importe_cuidado_nocturno" defaultValue={0} />
          </Grid>
          <Grid item xs={2} sx={{ maxWidth: "150px", maxHeight: "60px" }}>
            <NumberInput source="importe_talleres" defaultValue={0} />
          </Grid>
          <Grid item xs={2} sx={{ maxWidth: "150px", maxHeight: "60px" }}>
            <NumberInput source="importe_sesiones" defaultValue={0} />
          </Grid>
          <Grid item xs={2} sx={{ maxWidth: "150px", maxHeight: "60px" }}>
            <NumberInput source="importe_limpieza" defaultValue={0} />
          </Grid>
          <Grid item xs={4} sx={{ maxWidth: "150px", maxHeight: "60px" }}>
            <NumberInput source="adicionales" defaultValue={0} />
          </Grid>
        </Grid>
      </SimpleForm>
    </Create>
  );
};

const HonorariosValorHora = {
  list: HonorariosValorHoraList,
  edit: HonorariosValorHoraEdit,
  create: HonorariosValorHoraCreate,
};

export default HonorariosValorHora;
