import React from "react";
import {
  InfiniteList,
  Link,
  ListContextProvider,
  SimpleList,
} from "react-admin";
import { useGetList, useList } from "react-admin";
import { Warning } from "@mui/icons-material";
import utils from "../../utils";

const rowStyle = (record, index) => ({
  "&.MuiListItemText-root": {
    ".MuiListItemIcon-root": {
      verticalAlign: "super",
      minWidth: "32px",
      marginLeft: "6px",
    },
    ".MuiListItemText-root": {
      display: "inline-block",
    },
  },
});

const SimpleListCustom = (props) => {
  return (
    <SimpleList
      {...props}
      leftIcon={() => <Warning sx={{ color: utils.ragPalette.red }} />}
      rowSx={rowStyle}
      primaryText={(record) => {
        const path =
          "/" +
          record.target +
          ("/" + record.targetid || "") +
          ("/" + record.targettab || "");
        return <Link to={{ pathname: path }}>{record.categoria}</Link>;
      }}
      linkType={false}
      secondaryText={(record) => record.titulo}
    />
  );
};

export const Home = (props) => {
  const { data, isLoading } = useGetList("notificaciones");
  const listContext = useList({ data, isLoading });
  return (
    <ListContextProvider value={listContext}>
      <InfiniteList resource="notificaciones" exporter={false}>
        <SimpleListCustom />
      </InfiniteList>
    </ListContextProvider>
  );
};
