import React, { useEffect, useState } from "react";
import {
  AutocompleteInput,
  BooleanField,
  BooleanInput,
  Create,
  Datagrid,
  DateField,
  DateInput,
  Edit,
  EditButton,
  FunctionField,
  InfiniteList,
  ListButton,
  NumberField,
  NumberInput,
  ReferenceField,
  ReferenceInput,
  SimpleForm,
  SimpleShowLayout,
  TextField,
  TextInput,
  TopToolbar,
  UpdateButton,
  WithRecord,
  WrapperField,
  required,
  useRecordContext,
} from "react-admin";
import { supabase } from "../supabaseProvider";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Paper,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const filters = [
  <DateInput
    source="periodo"
    alwaysOn={true}
    validate={required()}
    parse={(dateString) => {
      if (!dateString) return null;
      const date = new Date(dateString);
      return new Date(date.getFullYear(), date.getMonth(), 1).toISOString();
    }}
  />,
];
const date = new Date();
date.setMonth(date.getMonth() - 1);
date.setDate(1);
const defaultFilterDate = date.toISOString();

const HorasMensualesPersonalList = (props) => {
  return (
    <InfiniteList
      {...props}
      filters={filters}
      filterDefaultValues={{ periodo: defaultFilterDate }}
      sort={{ field: "id_personal", order: "ASC" }}
      storeKey={false}
      emptyWhileLoading
    >
      <Datagrid bulkActionButtons={false} rowClick="edit">
        <DateField locales={"es-AR"} source="periodo" />
        <ReferenceField
          link={false}
          reference="personal"
          source="id_personal"
          label="Nombre"
        >
          <FunctionField
            source="id"
            render={(record) =>
              `#${record.id} ${record.nombre},${record.apellido}`
            }
          />
        </ReferenceField>
        <WrapperField sortable={false} label="Importe" textAlign="right">
          <LiquidacionComponent />
        </WrapperField>
        <WithRecord
          label="Pagado"
          render={(record) =>
            record && record.pagado === false ? (
              <UpdateButton
                label="Pagar"
                icon={<CloseIcon />}
                data={{ pagado: true }}
                mutationMode="pessimistic"
              />
            ) : (
              <BooleanField source="pagado" />
            )
          }
        />
        <ReferenceField
          link={false}
          reference="personal"
          source="id_personal"
          label="Alias/CBU"
        >
          <FunctionField
            link={false}
            sortable={false}
            source="alias"
            render={(record) => record.alias || record.cbu}
          />
        </ReferenceField>
        <ReferenceField
          reference="personal"
          source="id_personal"
          label="DNI/CUIL"
          link={false}
        >
          <FunctionField
            source="id"
            render={(record) => `${record.dni || " "} / ${record.cuil || " "}`}
          />
        </ReferenceField>
      </Datagrid>
    </InfiniteList>
  );
};

const filterToQuery = (search) =>
  search
    ? {
        "@or": {
          "nombre@ilike": `%${search}%`,
          "apellido@ilike": `%${search}%`,
        },
      }
    : {};

const HorasMensualesPersonalCreate = (props) => {
  return (
    <Create
      {...props}
      redirect={"list"}
      mutationMode="pessimistic"
      actions={
        <TopToolbar title="Horas Mensuals Personal">
          <ListButton label="Lista" />
        </TopToolbar>
      }
    >
      <SimpleForm>
        <Grid
          container
          width="100%"
          columns={4}
          rowSpacing={1}
          columnSpacing={1}
          sx={{ maxWidth: "550px" }}
        >
          <Grid item xs={2} sx={{ maxWidth: "150px", maxHeight: "60px" }}>
            <ReferenceInput
              source="id_personal"
              reference="personal"
              sort={{ field: "id", order: "ASC" }}
              fullWidth
            >
              <AutocompleteInput
                filterToQuery={filterToQuery}
                optionText={(personal) =>
                  `#${personal.id} ${personal.nombre},${personal.apellido}`
                }
              />
            </ReferenceInput>
          </Grid>
          <Grid item xs={2} sx={{ maxWidth: "150px", maxHeight: "60px" }}>
            <DateInput
              source="periodo"
              fullWidth
              validate={required()}
              defaultValue={defaultFilterDate}
              parse={(dateString) => {
                if (!dateString) return null;
                const date = new Date(dateString);
                return new Date(
                  date.getFullYear(),
                  date.getMonth(),
                  1
                ).toISOString();
              }}
            />
          </Grid>
          <Grid item xs={2} sx={{ maxWidth: "150px", maxHeight: "60px" }}>
            <NumberInput source="horas_cuidado_diurno" defaultValue={0} />
          </Grid>
          <Grid item xs={2} sx={{ maxWidth: "150px", maxHeight: "60px" }}>
            <NumberInput source="horas_cuidado_nocturno" defaultValue={0} />
          </Grid>
          <Grid item xs={2} sx={{ maxWidth: "150px", maxHeight: "60px" }}>
            <NumberInput source="horas_talleres" defaultValue={0} />
          </Grid>
          <Grid item xs={2} sx={{ maxWidth: "150px", maxHeight: "60px" }}>
            <NumberInput source="horas_sessiones" defaultValue={0} />
          </Grid>
          <Grid item xs={2} sx={{ maxWidth: "150px", maxHeight: "60px" }}>
            <NumberInput source="horas_limpieza" defaultValue={0} />
          </Grid>
          <Grid item xs={4} sx={{ maxWidth: "150px", maxHeight: "60px" }}>
            <NumberInput source="adicional" defaultValue={0} />
          </Grid>
          <Grid item xs={4} sx={{ maxHeight: "150px" }}>
            <TextInput
              source="notas"
              multiline
              fullWidth
              sx={{ minHeight: "140px", overflow: "auto", maxHeight: "140px" }}
            />
          </Grid>
        </Grid>
      </SimpleForm>
    </Create>
  );
};

const LiquidacionComponent = () => {
  const record = useRecordContext();
  const [liquidacion, setLiquidacion] = useState({});
  const [open, setOpen] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data, error } = await supabase.rpc("getliquidacion", {
          id_personalp: record.id_personal,
          periodop: record.periodo,
        });
        setLiquidacion(data[0]);
        if (error) console.error(error);
      } catch (error) {
        console.error(error);
      }
    };
    if (record && record.id_personal && record.periodo) fetchData();
  }, [record, setLiquidacion]); // Empty dependency array ensures this effect runs only once on mount

  return (
    <>
      <Button
        onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();
          setOpen(true);
        }}
      >
        {liquidacion.importe_liquidacion &&
          "$" + liquidacion.importe_liquidacion.toLocaleString()}
      </Button>
      <Dialog
        open={open}
        onClose={(e) => {
          e.stopPropagation();
          e.preventDefault();
          setOpen(false);
        }}
      >
        <DialogTitle id="alert-dialog-title">
          Liquidacion
          {` ${liquidacion.periodo} #${liquidacion.id_personal} - ${liquidacion.nombre_personal}`}
        </DialogTitle>
        <DialogContent
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
          }}
        >
          <SimpleShowLayout record={liquidacion}>
            <NumberField source="importe_liquidacion" />
            <TextField source="alias" />
            <TextField multiline="true" source="detalle_horas_texto" />
            <TextField multiline="true" source="mensaje" />
          </SimpleShowLayout>
        </DialogContent>
        <DialogActions>
          <EditButton />
          <Button
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              setOpen(false);
            }}
          >
            Cerrar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const HorasMensualesPersonalEdit = (props) => {
  return (
    <Edit
      {...props}
      redirect={"list"}
      mutationMode="pessimistic"
      actions={
        <TopToolbar title="Horas Mensuales Personal">
          <ListButton label="Lista" />
        </TopToolbar>
      }
    >
      <SimpleForm>
        <Grid
          container
          width="100%"
          columns={4}
          rowSpacing={1}
          columnSpacing={1}
          sx={{ maxWidth: "550px" }}
        >
          <Grid item xs={2} sx={{ maxWidth: "150px", maxHeight: "60px" }}>
            <ReferenceInput
              source="id_personal"
              reference="personal"
              sort={{ field: "id", order: "ASC" }}
              fullWidth
            >
              <AutocompleteInput
                filterToQuery={filterToQuery}
                optionText={(personal) =>
                  `#${personal.id} ${personal.nombre},${personal.apellido}`
                }
              />
            </ReferenceInput>
          </Grid>
          <Grid item xs={2} sx={{ maxWidth: "150px", maxHeight: "60px" }}>
            <DateInput
              source="periodo"
              fullWidth
              validate={required()}
              parse={(dateString) => {
                if (!dateString) return null;
                const date = new Date(dateString);
                return new Date(
                  date.getFullYear(),
                  date.getMonth(),
                  1
                ).toISOString();
              }}
            />
          </Grid>
          <Grid
            item
            xs={4}
            sx={{
              minHeight: "70px",
              maxHeight: "70px",
            }}
          >
            <Paper
              elevation={2}
              style={{
                height: "100%",
                width: "100%",
                margin: "3px 0px",
                padding: "2px 6px",
              }}
            >
              <ReferenceField
                reference="personal"
                source="id_personal"
                linkType={false}
              >
                <TextField
                  source="notas"
                  sx={{
                    minHeight: "100%",
                    maxHeight: "100%",
                    overflow: "auto",
                  }}
                  multiline
                />
              </ReferenceField>
            </Paper>
          </Grid>

          <Grid item xs={2} sx={{ maxWidth: "150px", maxHeight: "60px" }}>
            <NumberInput source="horas_cuidado_diurno" defaultValue={0} />
          </Grid>
          <Grid item xs={2} sx={{ maxWidth: "150px", maxHeight: "60px" }}>
            <NumberInput source="horas_cuidado_nocturno" defaultValue={0} />
          </Grid>
          <Grid item xs={2} sx={{ maxWidth: "150px", maxHeight: "60px" }}>
            <NumberInput source="horas_talleres" defaultValue={0} />
          </Grid>
          <Grid item xs={2} sx={{ maxWidth: "150px", maxHeight: "60px" }}>
            <NumberInput source="horas_sessiones" defaultValue={0} />
          </Grid>
          <Grid item xs={2} sx={{ maxWidth: "150px", maxHeight: "60px" }}>
            <NumberInput source="horas_limpieza" defaultValue={0} />
          </Grid>
          <Grid item xs={2} sx={{ maxWidth: "150px", maxHeight: "60px" }}>
            <NumberInput source="adicional" defaultValue={0} />
          </Grid>
          <Grid item xs={4} sx={{ maxWidth: "150px", maxHeight: "60px" }}>
            <BooleanInput source="pagado" />
          </Grid>
          <Grid item xs={4} sx={{ maxHeight: "150px" }}>
            <TextInput
              source="notas"
              multiline
              fullWidth
              sx={{ minHeight: "140px", overflow: "auto", maxHeight: "140px" }}
            />
          </Grid>
        </Grid>
      </SimpleForm>
    </Edit>
  );
};

const HorasMensualesPersonal = {
  list: HorasMensualesPersonalList,
  create: HorasMensualesPersonalCreate,
  edit: HorasMensualesPersonalEdit,
};

export default HorasMensualesPersonal;
