import "./App.css";
import { Admin, Resource } from "react-admin";
import { dataProvider, authProvider } from "./components/supabaseProvider";
import { LoginPage } from "ra-supabase-ui-materialui";
import { BrowserRouter, Route } from "react-router-dom";

import movimientos from "./components/movimientos";
import facturas from "./components/facturas";
import conveniosMaster from "./components/conveniosMaster";
import conveniosImportes from "./components/conveniosMaster/conveniosImportes";

import huespedes from "./components/huespedes";
import personal from "./components/personal";
import obrasSociales from "./components/obrasSociales";

import horasMensualesPersonal from "./components/horasMensualesPersonal";
import honorariosValorHora from "./components/honorariosValorHora";

import { Home } from "./components/home";
import { MainLayout } from "./components/layout";

const MovimientosCreate = movimientos.create;

function App() {
  return (
    <BrowserRouter>
      <Admin
        disableTelemetry
        basename=""
        dataProvider={dataProvider}
        authProvider={authProvider}
        layout={MainLayout}
        dashboard={Home}
        loginPage={<LoginPage disableForgotPassword={true} />}
      >
        <Resource name="notificaciones" />
        <Resource name="movimientos" {...movimientos} />
        <Resource name="facturas" {...facturas} />
        <Resource name="conveniosMaster" {...conveniosMaster} />
        <Resource name="conveniosImportes" {...conveniosImportes} />
        <Resource name="obrasSociales" {...obrasSociales} />

        <Resource name="huespedes" {...huespedes}>
          <Route
            path=":id_huesped/registrarPago"
            element={<MovimientosCreate />}
          />
        </Resource>
        <Resource name="personal" {...personal} />
        <Resource name="honorariosValorHora" {...honorariosValorHora} />
        <Resource name="horasMensualesPersonal" {...horasMensualesPersonal} />
      </Admin>
    </BrowserRouter>
  );
}

export default App;
