import React from "react";
import {
  TextInput,
  useRecordContext,
  SimpleForm,
  Edit,
  Create,
  DateInput,
  NumberInput,
  required,
} from "react-admin";

import { Grid } from "@mui/material";
import ToolbarFormCancel from "../misc/ToolbarFormCancel";

const ConveniosImportesForm = (props) => {
  const record = useRecordContext();
  return (
    <SimpleForm
      source={record}
      {...props}
      toolbar={<ToolbarFormCancel backOnSave={true} />}
    >
      <Grid
        container
        width="100%"
        sx={{ maxWidth: "500px" }}
        columns={4}
        rowSpacing={1}
        columnSpacing={1}
      >
        <Grid item xs={2} sx={{ maxWidth: "150px", maxHeight: "60px" }}>
          <DateInput source="desde" validate={required()} />
        </Grid>
        <Grid item xs={2} sx={{ maxWidth: "150px", maxHeight: "60px" }}>
          <DateInput source="hasta" validate={required()} />
        </Grid>
        <Grid item xs={2} sx={{ maxWidth: "150px", maxHeight: "60px" }}>
          <NumberInput source="importe_diario" validate={required()} />
        </Grid>
        <Grid item xs={2} sx={{ maxWidth: "150px", maxHeight: "60px" }}>
          <NumberInput source="importe_mensual" validate={required()} />
        </Grid>
        <Grid item xs={4}>
          <TextInput
            source="notas"
            multiline
            fullWidth
            sx={{ overflow: "auto", maxHeight: "140px" }}
          />
        </Grid>
      </Grid>
    </SimpleForm>
  );
};

const ConveniosImportesEdit = (props) => {
  return (
    <Edit {...props} mutationMode="pessimistic" actions={false}>
      <ConveniosImportesForm />
    </Edit>
  );
};

const ConveniosImportesCreate = (props) => {
  return (
    <Create {...props} mutationMode="pessimistic" actions={false}>
      <ConveniosImportesForm />
    </Create>
  );
};

const ConveniosImportes = {
  edit: ConveniosImportesEdit,
  create: ConveniosImportesCreate,
};

export default ConveniosImportes;
