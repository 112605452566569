import React from "react";
import {
  Datagrid,
  TextField,
  required,
  NumberField,
  InfiniteList,
  TextInput,
  useRecordContext,
  SimpleForm,
  Edit,
  TopToolbar,
  ListButton,
  Create,
  ReferenceManyField,
  DateField,
  CreateButton,
  ReferenceOneField,
} from "react-admin";

import { Grid } from "@mui/material";

const ConveniosMasterList = (props) => {
  return (
    <InfiniteList {...props} storeKey={false}>
      <Datagrid bulkActionButtons={false} rowClick="edit">
        <TextField source="nombre" sortable={false} />
        <ReferenceOneField
          label="Ultimo Periodo"
          reference="conveniosImportes"
          target="id_convenio"
          sort={{ field: "desde", order: "DESC" }}
        >
          <DateField source="desde" locales={"es-AR"} />
        </ReferenceOneField>
        <TextField
          source="notas"
          sx={{
            minHeight: "100%",
            maxHeight: "100%",
            overflow: "auto",
          }}
          multiline
        />
      </Datagrid>
    </InfiniteList>
  );
};

const ConvenioMasterForm = (props) => {
  const record = useRecordContext();
  return (
    <SimpleForm source={record} {...props}>
      <Grid container width="100%" columns={4} rowSpacing={1} columnSpacing={1}>
        <Grid item xs={4}>
          <TextInput source="nombre" fullWidth validate={required()} />
        </Grid>
        <Grid item xs={4}>
          <TextInput
            source="notas"
            multiline
            fullWidth
            sx={{ overflow: "auto", maxHeight: "140px" }}
          />
        </Grid>
        {record && record.id > 0 && (
          <Grid item xs={4}>
            <ReferenceManyField
              reference="conveniosImportes"
              target="id_convenio"
              label="Importes"
            >
              <CreateButton
                state={{
                  record: { id_convenio: record.id },
                }}
              />
              <Datagrid rowClick="edit" bulkActionButtons={false}>
                <DateField source="desde" locales="es-AR" />
                <DateField source="hasta" locales={"es-AR"} />
                <NumberField source="importe_diario" label="Diario S/IVA" />
                <NumberField source="importe_mensual" label="Mensual S/IVA" />
                <NumberField
                  label="Diario 10.5%"
                  source="importe_diario"
                  transform={(v) => v * 1.105}
                />
                <NumberField
                  label="Mensual 10.5%"
                  source="importe_mensual"
                  transform={(v) => v * 1.105}
                />
                <NumberField
                  source="importe_diario"
                  label="Diario 21%"
                  transform={(v) => v * 1.105}
                />
                <NumberField
                  source="importe_mensual"
                  label="Mensual 21%"
                  transform={(v) => v * 1.105}
                />
                <TextField source="notas" multiline />
              </Datagrid>
            </ReferenceManyField>
          </Grid>
        )}
      </Grid>
    </SimpleForm>
  );
};

const ConvenioMasterEdit = (props) => {
  return (
    <Edit
      {...props}
      redirect={"list"}
      mutationMode="pessimistic"
      actions={
        <TopToolbar title="Convenios">
          <ListButton label="Convenios" />
        </TopToolbar>
      }
    >
      <ConvenioMasterForm />
    </Edit>
  );
};

const ConvenioMasterCreate = (props) => {
  return (
    <Create
      {...props}
      redirect={"list"}
      mutationMode="pessimistic"
      actions={
        <TopToolbar title="Convenios">
          <ListButton label="Convenios" />
        </TopToolbar>
      }
    >
      <ConvenioMasterForm />
    </Create>
  );
};

const ConveniosMaster = {
  list: ConveniosMasterList,
  edit: ConvenioMasterEdit,
  create: ConvenioMasterCreate,
};

export default ConveniosMaster;
